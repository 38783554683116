import Store from './store'
require('./menuModule')
require('./userModule')
require('./actionModule')
require('./spaceActionModule')
require('./projectModule')
require('./logoModule.js')
require('./spacePermiss.js')
require('./tableShowModule.js')
require('./spaceInfoModule.js')
require('./bimModelModule.js')
export default Store
