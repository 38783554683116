import Store from './store'

Store.registerModule('project', {
  namespaced: true,
  state: {
    space: [],
    projectType: [],
    project: []
  },
  getters: {},
  mutations: {
    initSpace (state, space) {
      state.space = space
    },
    initProjectType (state, projectType) {
      state.projectType = projectType
    },
    initProject (state, project) {
      state.project = project
    }
  },
  actions: {
    initSpace (context, space) {
      context.commit('initSpace', space)
    },
    initProjectType (context, projectType) {
	  context.commit('initProjectType', projectType)
    },
    initProject (context, project) {
	  context.commit('initProject', project)
    }
  }
})
