import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '@/libs/versionUpdate'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [{
  path: '*',
  name: '/404',
  component: () => import('../views/404.vue')
},
{
  path: '/login',
  name: 'login',
  component: () => import('../views/Login.vue')
},
{
  path: '/forgetPwd',
  name: 'forgetPwd',
  component: () => import('../views/ForgetPwd.vue')
},
{
  path: '/emdatahelp',
  name: 'emdatahelp',
  component: () => import('../views/EmdataHelp.vue')
},

{
  path: '/home',
  name: 'home',
  redirect: '/home/table',
  component: () => import('../views/Home.vue'),
  children: [{
    path: 'index',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: 'user',
    name: 'user',
    component: () => import('../views/system/User.vue')
  },
  {
    path: 'role',
    name: 'role',
    component: () => import('../views/system/Role.vue')
  },
  {
    path: 'permission',
    name: 'permission',
    component: () => import('../views/system/Permission.vue')
  },
  {
    path: 'organization',
    name: 'organization',
    component: () => import('../views/system/Organization.vue')
  },
  {
    path: 'table',
    name: 'table',
    component: () => import('../views/system/TableList.vue')
  },
  {
    path: 'modellist',
    name: 'modellist',
    component: () => import('../views/bimmodel/ModelList.vue')
  },
  {
    path: 'model',
    name: 'model',
    component: () => import('../views/bimmodel/Index.vue')
  },
  {
    path: 'log',
    name: 'log',
    component: () => import('../views/system/Log.vue')
  },
  {
    path: 'template',
    name: 'template',
    component: () => import('../views/system/Template.vue')
  },
  {
    path: 'codingRules',
    name: 'codingRules',
    component: () => import('../views/system/CodingRule.vue')
  },
  {
    path: 'newCodingRule',
    name: 'newCodingRule',
    component: () => import('../views/system/newCodingRule.vue')
  },
  {
    path: 'system',
    name: 'system',
    component: () => import('../views/system/System.vue')
  },
  {
    path: 'codingStandard',
    name: 'codingStandard',
    component: () => import('../views/coding/CodingStandardList.vue')
  },
  {
    path: 'projectuser',
    name: 'projectuser',
    component: () => import('../views/system/ProjectUser.vue')
  },
  {
    path: '/space',
    name: 'space',
    component: () => import('../views/Space.vue')
  },
  {
    path: '/projectType',
    name: 'projectType',
    component: () => import('../views/ProjectType.vue')
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/adminSpace',
    name: 'adminSpace',
    component: () => import('../views/AdminSpace.vue')
  },
  {
    path: '/ownProjectType',
    name: 'ownProjectType',
    component: () => import('../views/OwnProjectType.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('../views/system/UserInfo.vue')
  },
  {
    path: '/companyproject',
    name: 'companyproject',
    component: () => import('../views/system/CompanyProject.vue')
  },
  {
    path: 'commoncodenumber',
    name: 'commoncodenumber',
    component: () => import('../views/system/Code.vue')
  },
  {
    path: 'commondataspecification',
    name: 'commondataspecification',
    component: () => import('../views/system/TemplateCom.vue')
  },
  {
    path: 'companycodenumber',
    name: 'companycodenumber',
    component: () => import('../views/system/Code.vue')
  },
  {
    path: 'companydataspecification',
    name: 'companydataspecification',
    component: () => import('../views/system/TemplateCom.vue')
  },
  {
    path: '/commoncodenumber',
    name: 'commoncodenumber',
    component: () => import('../views/system/Code.vue')
  },
  {
    path: '/commondataspecification',
    name: 'commondataspecification',
    component: () => import('../views/system/TemplateCom.vue')
  },
  {
    path: '/companycodenumber',
    name: 'companycodenumber',
    component: () => import('../views/system/Code.vue')
  },
  {
    path: '/companydataspecification',
    name: 'companydataspecification',
    component: () => import('../views/system/TemplateCom.vue')
  }
  ]
}, {
  path: '/',
  name: 'website',
  component: () => import('../views/officialwebsite/index.vue'),
  redirect: '/index',
  children: [{
    path: '/index',
    name: 'index',
    component: () => import('../views/officialwebsite/HomePage.vue')
  }, {
    path: '/product',
    name: 'product',
    component: () => import('../views/officialwebsite/Product.vue')
  }, {
    path: '/community',
    name: 'community',
    component: () => import('../views/officialwebsite/Community.vue')
  }, {
    path: '/download',
    name: 'download',
    component: () => import('../views/officialwebsite/Download.vue')
  }, {
    path: '/business',
    name: 'business',
    component: () => import('../views/officialwebsite/Business.vue')
  }]
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 添加路由守卫，路由守卫应该不管首页相关的
router.beforeEach((to, from, next) => {
	 // 判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion()
  if (to.path == '/index/') {
    next('/index')
    return
  }
  if (to.path == '/product/') {
    next('/product')
    return
  }
  if (to.path == '/download/') {
    next('/download')
    return
  }
  if (to.path == '/business/') {
    next('/business')
    return
  }
  if (to.path == '/community/' || to.path == '/community') {
    let url = `/version.json?t=${new Date().getTime()}`
    axios.get(url).then(res => {
      if (res.bbsUrl) {
        // 首先创建一个form表单
        var tempForm = document.createElement('form')
        tempForm.id = 'tempForm1'
        // 制定发送请求的方式为post
        tempForm.method = 'post'
        // 此为window.open的url，通过表单的action来实现
        tempForm.action = res.bbsUrl
        // 利用表单的target属性来绑定window.open的一些参数（如设置窗体属性的参数等）
        // tempForm.target="_blank";   //在新界面打开
        const token = localStorage.getItem('eleToken')
        if (token) {
          // 创建input标签，用来设置参数
          var hideInput = document.createElement('input')
          hideInput.type = 'hidden'
          hideInput.name = 'code'
          hideInput.value = token
          // 将input表单放到form表单里
          tempForm.appendChild(hideInput)
          // 将input表单放到form表单里
          tempForm.appendChild(hideInput)
        }
        // 将此form表单添加到页面主体body中
        document.body.appendChild(tempForm)
        // 手动触发，提交表单
        tempForm.submit()
        // 从body中移除form表单
        document.body.removeChild(tempForm)
        // window.open(url, "_blank");
        if (window.history.length <= 1) {
          this.$router.push({ path: '/index' })
          return false
        } else {
          this.$router.push({ path: '/index' })
        }
      } else {
        this.$message({
          type: 'error',
          message: 'bbs 路径无效'
        })
      }
    })

    return
  }

  if (to.path == '/index' || to.path == '/product' || to.path == '/community' || to.path == '/download' || to.path == '/business') {
    next()
    return
  }
  const isLogin = !!localStorage.eleToken
  if (to.path === '/login' || to.path === '/emdatahelp' || to.path === '/adminSpace' || to.path === '/ownProjectType' || to.path === '/forgetPwd' || to.path === '/userinfo' || to.path === '/companyproject' || to.path === '/commoncodenumber' || to.path === '/commondataspecification' || to.path === '/companycodenumber' || to.path === '/companydataspecification') {
    next()
  } else {
    if (isLogin) {
      // 判断是否选择了空间
      const isSpace = !!localStorage.eleSpaceId
      if (to.path === '/space') {
        next()
      } else {
        if (isSpace) {
          // 判断是否选择了项目分类
          const isProjectType = !!localStorage.eleProjectTypeId
          if (to.path === '/projectType') {
            next()
          } else {
            if (isProjectType) {
              // 判断是否选择了项目
              const isProject = !!localStorage.eleProjectId
              if (to.path === '/project') {
                next()
              } else {
                isProject ? next() : next('/project')
              }
            } else {
              next('/projectType')
            }
          }
        } else {
          next('/space')
        }
      }
    } else {
      next('/login')
    }
    // isLogin ? next() : next('/login')
  }
})
// 解决路由下的界面，点击该路由多次时
// 报错：NavigationDuplicated{_name: "NavigationDuplicated", name: "NavigationDuplicated", message: "Navigating to current location ("/one") is not allowed", stack: "Error↵    at new NavigationDuplicated (webpack-int…e_modules/element-ui/lib/mixins/emitter.js:29:22)"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
