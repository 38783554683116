import Store from './store'

Store.registerModule('spacePermiss', {
  namespaced: true,
  state: {
    spacePermiss: { isSmartAdmin: false, isSpaceOwner: false, isAdmin: false }
  },
  getters: {},
  mutations: {
    initSpacePermiss (state, action) {
      state.spacePermiss = action
    }
  },
  actions: {
    initSpacePermiss (context, action) {
      context.commit('initSpacePermiss', action)
    }
  }
})
