import Store from './store'

Store.registerModule('action', {
  namespaced: true,
  state: {
    action: {}
  },
  getters: {},
  mutations: {
    initAction (state, action) {
      state.action = action
    }
  },
  actions: {
    initAction (context, action) {
      context.commit('initAction', action)
    }
  }
})
