import Store from './store'

Store.registerModule('tableShow', {
  namespaced: true,
  state: {
    showViewId: 0,
    createUserId: 0
  },
  getters: {},
  mutations: {
    initShowViewId (state, action) {
      state.showViewId = action
    },
    initCreateUserId (state, action) {
      state.createUserId = action
    }
  },
  actions: {
    initShowViewId (context, action) {
      context.commit('initShowViewId', action)
    },
    initCreateUserId (context, action) {
      context.commit('initCreateUserId', action)
    }
  }
})
