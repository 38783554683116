// 两种方式实现vue项目发布后，缓存问题
// 1. 在nginx中配置，防止index.html缓存
// location  /index.html {
//   add_header Cache-Control "no-cache, no-store";
// }

// 2. 下面这种方式，可以实现版本更新逻辑。
import axios from 'axios'

const isNewVersion = () => {
  let url = `/version.json?t=${new Date().getTime()}`
  axios.get(url).then(res => {
    if (res.version) {
      let currSysVersion = res.version
      let localVueVersion = localStorage.getItem('currSysVersion')
      if (localVueVersion && localVueVersion != currSysVersion) {
        console.log('版本有更新')
        localStorage.setItem('currSysVersion', currSysVersion)
        window.location.reload()
      } else {
        console.log('版本无更新')
        localStorage.setItem('currSysVersion', currSysVersion)
      }
    }
  })
}

export default {
  isNewVersion
}
