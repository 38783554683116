import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import animated from 'animate.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from './utils/http.js'
import utils from './utils/utils.js'
import uploader from 'vue-simple-uploader'
import WebSql from './WebSql/WebSql.js'
import MetaInfo from 'vue-meta-info'
import i18n from './language'

Vue.use(animated)
Vue.use(uploader)
Vue.use(WebSql)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(MetaInfo)
// 绑定到vue原型链上去
Vue.prototype.$axios = axios
Vue.prototype.$utils = utils

// 界面刷新前，将store里面的数据暂存在localStorage里面
window.addEventListener('beforeunload', () => {
  localStorage.setItem('userInfo', JSON.stringify(store.state.user.userInfo))
  // localStorage.setItem('tabs', JSON.stringify(store.state.menu.tabs))
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  // 添加到这里,这里的render-event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
// 数字化地址
if( process.env.NODE_ENV === 'development'){
Vue.prototype.$digitalize = 'http://localhost:12000/#/login'
}else{
  Vue.prototype.$digitalize = 'https://www.emdataer.com:8080/#/login'
}

function rewirteLog () {
  if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
  }
}
rewirteLog()
