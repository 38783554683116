import Store from './store'

Store.registerModule('spaceAction', {
  namespaced: true,
  state: {
    spaceAction: {}
  },
  getters: {},
  mutations: {
    initspaceAction (state, spaceAction) {
      state.spaceAction = spaceAction
    }
  },
  actions: {
    initspaceAction (context, spaceAction) {
      context.commit('initspaceAction', spaceAction)
    }
  }
})
