import Store from './store'

Store.registerModule('bimModel', {
  namespaced: true,
  state: {
    modelData: [], // 点击更多过滤时候里面加载样表的属性的数据
    moreFilterData: [], // 添加到更多过滤里面的数据
    filterChecked: [], // 更多过滤里面选中的数据
    reviewData: [], // +属性中选择的数据
    floorData: [], // 存储左侧楼层最初的基本数据
    spaceData: [], // 存储左侧空间基本数据
    revitData: [],
    floorChecked: [], // 楼层中被选择之后拿到的数据
    spaceChecked: [], // 空间中被i选择之后拿到的数据
    categoryData: [], // 过滤器里面的revit构件类型数据
    autoHeader: [],
    filterObj: {},
    moreFilterChecked: [],
    isLoading: true,
    familyAndType: [],
    cacheState: false,
    allGuid: []
  },
  getters: {},
  mutations: {
    initCacheState (state, action) {
      state.cacheState = action
    },
    initAllGuid (state, action) {
      state.allGuid = action
    },
    initFamilyAndType (state, action) {
      console.log(action)
      state.familyAndType = action
    },
    initIsLoading (state, action) {
      state.isLoading = !state.isLoading
    },
    initMoreFilterChecked (state, action) {
      state.moreFilterChecked = action
    },
    initAutoHeader (state, action) {
      state.autoHeader = action
    },
    initFilterObj (state, action) {
      state.filterObj = action
    },
    initFloorChecked (state, action) {
      state.floorChecked = action
    },
    initSpaceChecked (state, action) {
      state.spaceChecked = action
    },
    initRevitData (state, action) {
      state.revitData = action
    },
    initFloorData (state, action) {
      state.floorData = action
    },
    initSpaceData (state, action) {
      state.spaceData = action
    },
    initModelData (state, action) {
      state.modelData = action
    },
    // 初始化整个bimModel里面的数据
    initBimModel (state, action) {
      state.moreFilterData = []
      state.filterChecked = []
      state.reviewData = []
      state.floorData = []
      state.spaceData = []
      state.revitData = []
      state.floorChecked = []
      state.spaceChecked = []
      state.categoryData = []
      state.filterObj = {}
      state.moreFilterChecked = []
      state.isLoading = true
      state.familyAndType = []
      state.autoHeader = []
      state.cacheState = false
      state.allGuid = []
    },
    initMoreFilterData (state, action) {
      state.moreFilterData = action
    },
    initFilterChecked (state, action) {
      state.filterChecked = action
    },
    initReviewData (state, action) {
      state.reviewData = action
    },
    initCategoryData (state, action) {
      state.categoryData = action
    }
  },
  actions: {}
})
