import Store from './store'

Store.registerModule('logo', {
  namespaced: true,
  state: {
    logo: ''
  },
  getters: {},
  mutations: {
    initLogo (state, logo) {
      state.logo = logo
    }
  },
  actions: {
    initLogo (context, logo) {
      context.commit('initAction', logo)
    }
  }
})
