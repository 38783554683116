import Store from './store'

Store.registerModule('user', {
  namespaced: true,
  state: {
    userInfo: {}
  },
  getters: {},
  mutations: {
    initUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    initUserInfo (context, userInfo) {
      context.commit('initUserInfo', userInfo)
    }
  }
})
