export default {
  basic: {
    language: '预览',
    guide: '任何事宜，可社区留言或联系',
    development:'拼命开发',
  },
  home: {
    titleOne: '消除技术门槛',
    titleTwo: '整合建筑信息',
    titleThree: '人人皆可使用并从中受益',
    inlet: '进入',
    companyUsers: '公司用户',
    individualUsers: '个人用户',
    item: '项目数量',
    form: '表单数据',
    email: '邮箱',
    mob: '电话',
    kf: '客服',
    gzh: '公众号(含教学课程)'
  },
  header: {
    home: '首页',
    products: '产品',
    community: '社区',
    download: '下载',
    business: '商务',
    register: '注册',
    personalInformation: '个人信息',
    personalAccount: '个人账号',
    corporateAccount: '企业账号',
    esc: '退出',
    digitalize:'数字化',
  },
  common: {
    login: '登录',
    userName: '账号',
    password: '密码',
    search:'搜索',
    sort:'排序',
    confirmation:'确认',
    cancel:'取消',
    save:'保存',
    delete:'删除',
    configuration:'企业设置',
    softwareDownloading:'软件下载',
    otherDownloads:'其它下载',
    owner:'拥有者',
    uploading:'上传',
    pleaseChoose:'请选择',
    pleaseEnter:'请输入',
    reset:'重置',
  },
  login: {
    remember: '记住密码',
    Forgot: '忘记密码',
    Noaccount: '没有账号',
    firstRegistration: '免费注册'
  },
  spaceType:{
    personalSpace:'个人空间',
    joinCompany:'已加入的公司',
    backstage:'进入后台',
    history:'项目浏览记录',
    createGroup:'创建分组',
    editGrouping:'编辑分组',
    componentName:'分组名称',
    enterpriseCoding:'企业编码',
    cover:'封面图',
  },
  project:{
    name:'项目名称',
    coding:'项目编码',
    address:'项目地址',
    cover:'封面图',
    monomer:'单体',
    monomerName:'单体名称',
    monomercoding:'单体编码',
    floorone:'地上楼层数',
    floorTwo:'地下楼层数',   
    create:'新建项目',
    itemSetting:'项目设置',
    editItem:'编辑项目'
  },
  encoder:{
    encodedLibrary:'分类编码库',
    standard:'暂未选择编码标准',
    sharedCoding:'共享编码',
    enterprise:'目标企业',
    folder:'目标文件夹',
  },
  property:{
    specification:'属性规格库',
    classification:'属性分类列表',
    attribute:'属性列表',
    sampleName:'样表名称',
    copyLocation:'复制位置',
    targetEnterprise:'目标企业',
    folder:'目标文件夹',
    enterprise:'企业',
    property:'项目',
    publicSpace:'公共空间',
    targetItem:'目标项目',
  },
  codingRule:{
    createRule:'创建规则',
    help:'帮助',
    ruleName:'规则名称',
    addFloor:'插入楼层',
    addCodification:'插入分类编码',
    addSerialNumber:'插入流水号',
    additiveAttribute:'插入属性字段',
    addUserDefined:'插入自定义',
    saveSettings:'保存设置',
    mappingTable:'映射表格',
    readingFloor:'读取楼层',
    readParameter:'读取参数',
    defineMappings:'定义编码及映射列',
    writeContent:'请输入内容',

   
  },
}
