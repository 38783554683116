import axios from 'axios'
import {
  Message,
  Loading
} from 'element-ui'
import router from '../router/index'

let loading // 定义loading变量

function startLoading () { // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: '加载中...',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

function endLoading () { // 使用Element loading-close 方法
  loading.close()
}

// 请求拦截  设置统一header
axios.interceptors.request.use(config => {
  // 加载
  console.log(config.url)
  let type = router.history.current.name != 'model'
  if (config.url != '/api/homepage/gethomepageinfo' && config.url !=
		'/api/systemother/getprojectandothernum' && type &&
		config.url.indexOf('version.json') == -1 && config.url != '/api/product/getProducts') {
    console.log('进来了')
    startLoading()
  }
  if (localStorage.eleToken) {
    config.headers.Authorization = localStorage.eleToken
  }
  if (localStorage.eleSpaceId) {
    config.headers.currSpaceId = localStorage.eleSpaceId
  }
  if (localStorage.eleProjectId) {
    config.headers.currProjectId = localStorage.eleProjectId
  }
  if (localStorage.eleProjectTypeId) {
    config.headers.currProjectTypeId = localStorage.eleProjectTypeId
  }
  if (localStorage.modelInfo) {
    var modelInfo = JSON.parse(localStorage.modelInfo)
    config.headers.currModelGuid = modelInfo.modelGuid
    config.headers.currModelDbPath = modelInfo.modelDBUrl
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截  token过期处理
axios.interceptors.response.use(response => {
  // console.log(response)\
  let type = router.history.current.name != 'model'
  if (response.config.url != '/api/homepage/gethomepageinfo' && response.config.url !=
		'/api/systemother/getprojectandothernum' && type &&
		response.config.url.indexOf('version.json') == -1 && response.config.url != '/api/product/getProducts') {
    endLoading()
  }
  // 是下载的时候需要单独拿出来，不能直接跳转，
  if (response.data.type == 'application/octet-stream') {
    return response.data
  }
  if (response.data.success || response.data.Success) {
    return response.data.result || response.data.message
  } else {
    if (response.data.Code == 401) {
      // Message.error('token值无效，请重新登录')
      // 清除token
      localStorage.removeItem('eleToken')
      // 清除projectId
      localStorage.removeItem('eleProjectId')
      // 页面跳转
      router.push('/login')
    } else if (response.data.message || response.data.Message) {
      console.log(111)
      Message.error(response.data.message || response.data.Message)
      return Promise.reject(response.data.message || response.data.Message)
      // }
    } else {
      return response.data
    }
  }
}, error => {
  // 错误提醒
  endLoading()
  // Message.error(error.response.message)
  const {
    Code
  } = error.response
  // console
  if (status === 401) {
    // Message.error('token值无效，请重新登录')
    // 清除token
    localStorage.removeItem('eleToken')
    // 清除projectId
    localStorage.removeItem('eleProjectId')
    // 页面跳转
    router.push('/login')
  } else {
    Message.error(error)
  }
  return Promise.reject(error)
})

export default axios
