<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import * as signalR from '@microsoft/signalr'
import highlightMeth from './utils/highlightmth.js'
let signalrUrl = 'http://localhost:60999/chatHub'
var connection
export default {
  data () {
    return {
      // rout: false,
      // oldid:'',
      // flg:true
    }
  },
  created () {
    // 在页面加载时读取sessionStorage里的状态信息
    // console.log(store)
    let storeName = localStorage.getItem('userName')
    // if (localStorage.getItem(storeName) ) {
    // console.log(localStorage.getItem("store"))
    this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(localStorage.getItem('store'))))
    // }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      console.log('存入vuex中的数据')
      localStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted () {
    this.initBimModel([])
    // this.oldid = localStorage.getItem('eleProjectId')
  },
  methods: {
    ...mapMutations('bimModel', {
			  initBimModel: 'initBimModel'
    })
    // ...mapMutations("action", {
    //   initAction: "initAction"
    // }),
    // ...mapMutations("menu",{
    // 	initMenu:"initMenu"
    // }),
    // authoritySpace(message){
    // 	// localStorage.setItem('eleSpaceId', space.id)
    // 	// if(!localStorage.getItem('eleSpaceId')){
    // 	// 	return
    // 	// }
    // 	// var id = localStorage.getItem('eleSpaceId')
    // 	let id = 1
    // 	this.$axios.get(`/api/space/getuserpermissionbyspaceid?spaceId=${id}`).then(res=>{
    // 		console.log(res)
    // 		var roleList = res.permissions
    // 		var actitons = {}
    // 		for (var item of roleList) {
    // 			actitons[item.permissionDescribe] = true
    // 		}
    // 		// console.log(actitons)
    // 		this.initspaceAction(actitons)
    // 		this.authority(message)
    // 	})
    // },
    // authority(message){
    // 	let id = localStorage.getItem('eleProjectId')
    // 	this.$axios.get("/api/Project/GetUserPermissionByprojectId?projectId="+id).then(res => {
    // 		console.log(res)
    // 		console.log(res)
    // 		var roleList = res.permissions
    // 		var actitons = {}
    // 		for (var item of roleList) {
    // 			actitons[item.permissionDescribe] = true
    // 		}
    // 		this.initAction(actitons)
    // 		// 找出所有menu
    // 		let menuList = roleList.filter(item=>{
    // 			return item.permissionType == 'Menu'
    // 		})
    // 		menuList = menuList.sort(function(a,b){
    // 			return a.sort - b.sort
    // 		})
    // 		// 找出所有父级menu
    // 		let parmenuList = menuList.filter(item2=>{
    // 			return item2.parentID == 0
    // 		})

    // 		// // 将每个父级的子级存进去
    // 		var menus = []
    // 		for(let item3 of parmenuList){
    // 			let obj = {}
    // 			obj.name = item3.permissionName
    // 			obj.index = item3.permissionDescribe
    // 			obj.icon = item3.icon
    // 			obj.includeCom = item3.permissionDescribe
    // 			let arr = menuList.filter(item4=>{
    // 				return item4.parentID == item3.id
    // 			})
    // 			if(arr.length != 0){
    // 				obj.hasChilder = true
    // 				// obj.children = arr
    // 				obj.children = []
    // 				for(let child of arr){
    // 					let objc = {}
    // 					objc.name = child.permissionName
    // 					objc.index = child.permissionDescribe
    // 					objc.icon = child.icon
    // 					objc.includeCom = child.permissionDescribe
    // 					objc.hasChilder = false
    // 					obj.children.push(objc)
    // 				}
    // 			}else{
    // 				obj.hasChilder = false
    // 			}
    // 			menus.push(obj)
    // 		}
    // 		this.initMenu(menus)
    // 		this.tableHighlight(message)
    // 	})
    // },
    // tableHighlight(message) {
    // 	let newArr = message.split('@')
    // 	var elementList = newArr[2].split(',')
    // 	// console.log(newArr[2].split(','))
    // 	// return
    // 	// 将table所在的项目的各个参数更新一下
    // 	localStorage.setItem('eleProjectId', 1)
    // 	localStorage.setItem('eleSpaceId', 1)
    // 	localStorage.setItem('eleProjectTypeId', 1)
    // 	// 跳转页面
    // 	// this.$router.push('/table')
    // 	// 改变页面中的参数
    // 	var data = {
    // 			mappingtable: newArr[0],
    // 			tablename:newArr[1],
    // 			highElementIds:elementList
    // 		}
    // 	if(this.$route.path == '/table'){
    // 		highlightMeth.$emit('high',data)
    // 		return
    // 	}
    // 	this.$router.push({
    // 		name: 'table',
    // 		params: data
    // 	})
    // 	console.log('接收到高亮')
    // }
  }
  // watch: {
  // 	$route(to, from) {
  // 		if (to.path == '/' || to.path == '/login') {
  // 			this.rout = false
  // 		} else {
  // 			//.net core 版本中默认不会自动重连，需手动调用 withAutomaticReconnect
  // 			if (this.rout) {
  // 				return
  // 			}
  // 			connection = new signalR.HubConnectionBuilder().withAutomaticReconnect().withUrl(signalrUrl, {
  // 				accessTokenFactory: () => localStorage.USER_ID
  // 			}).build();
  // 			connection.start().catch(err => this.$notify({
  // 				title: 'socket 连接失败',
  // 				message: err.message,
  // 				type: 'error'
  // 			}));
  // 			//上线
  // 			connection.on("OnLine", (message) => {
  // 				console.log("上线：")
  // 				console.log(message)
  // 			});

  // 			//离线
  // 			connection.on("OffLine", (message) => {
  // 				console.log("离线：")
  // 				console.log(message)
  // 			});

  // 			//被挤下线
  // 			connection.on("PassiveDisconnected", (message) => {
  // 				console.log(message)
  // 				this.$confirm('账号被异地登录，请注意账号风险, 是否重新登录?', '提示', {
  // 					confirmButtonText: '确定',
  // 					cancelButtonText: '取消',
  // 					type: 'warning'
  // 				}).then(() => {
  // 					location.reload();
  // 				}).catch(() => {
  // 					var userName = localStorage.getItem('userName')
  // 					var password = localStorage.getItem('passWord')
  // 					localStorage.clear()
  // 					this.$router.push("/login");
  // 					localStorage.setItem('userName', userName)
  // 					localStorage.setItem('passWord', password)
  // 				});
  // 			});

  // 			//其他消息通知
  // 			connection.on("OnNotify", (message) => {
  // 				// console.log(message)
  // 				// console.log(message.split("@"))
  // 				// return
  // 				this.$notify({
  // 					title: 'revit端指令',
  // 					message: "构件高亮",
  // 					type: 'success'
  // 				});
  // 				this.authoritySpace(message)
  // 				// this.authority()

  // 			});
  // 			//主动往服务器推送消息
  // 			// setTimeout(()=>{
  // 			//   this.sendToServer();
  // 			// },3000)

  // 			//客户端主动接收服务端推送的消息
  // 			connection.on("sendToClient", (message) => {
  // 				console.log("hhhhhhhhhhhhhhh")
  // 				console.log(message)
  // 				this.$notify({
  // 					title: '成功',
  // 					message: "上传成功",
  // 					type: 'success'
  // 				});
  // 			});
  // 			this.rout = true
  // 		}
  // 	}
  // },
}
</script>
<style lang="less">
	@import './assets/css/model.less';
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%;
	}

	html,
	body {
		margin: 0;
		padding: 0;
		// min-height: px;
		min-width: 1920px;
	}
	.statementPopover{
		background-color: #fbfbfb !important;
		border: none !important;
		.popper__arrow{
			display: none !important;
		}
		.el-tree{
			background-color: transparent;
			color: @fontColor;
		}
		.el-tree-node__content:hover{
			background-color: transparent;
		}
		.el-tree-node:focus>.el-tree-node__content {
			background-color: transparent;
		}
		.el-button--danger{
			background-color: @backgroundColor;
			color: @fontColor;
			border-color: @borderColor;
		}
		.el-button--danger:focus, .el-button--danger:hover{
			background-color: @backgroundColor;
			color: @fontColor;
			border-color: @borderColor;
		}
	}
</style>
