import Store from './store'

Store.registerModule('spaceInfo', {
  namespaced: true,
  state: {
    personalSpaceId: '',
    companySpaceId: '',
    inSpaceId: '',
    personalSpace: {},
    companySpace: {}
  },
  getters: {},
  mutations: {
    initPersonalSpaceId (state, personalSpaceId) {
      state.personalSpaceId = personalSpaceId
    },
    initCompanySpaceId (state, companySpaceId) {
      state.companySpaceId = companySpaceId
    },
    initPersonalSpace (state, personalSpace) {
      state.personalSpace = personalSpace
    },
    initCompanySpace (state, companySpace) {
      state.companySpace = companySpace
    },
    initInSpaceId (state, inSpaceId) {
      state.inSpaceId = inSpaceId
    }
  },
  actions: {
    initPersonalSpaceId (context, personalSpaceId) {
      context.commit('initPersonalSpaceId', personalSpaceId)
    },
    initCompanySpaceId (context, companySpaceId) {
      context.commit('initCompanySpaceId', companySpaceId)
    },
    initCompanySpace (context, companySpace) {
      context.commit('initCompanySpace', companySpace)
    },
    initPersonalSpace (context, personalSpace) {
      context.commit('initPersonalSpace', personalSpace)
    },
    initInSpaceId (context, inSpaceId) {
      context.commit('initInSpaceId', inSpaceId)
    }
  }
})
