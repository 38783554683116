export default {
  basic: {
    welcome: 'Welcome',
    guide:
      'How can I leave a message or contact the community',
    development:'In development',
  },
  home: {
    titleOne: 'Eliminating technical barriers',
    titleTwo: ' integrate building information',
    titleThree: 'everyone can use and benefit from it',
    inlet: 'enter',
    companyUsers: 'corporate users',
    individualUsers: 'individual users',
    item: 'number of projects',
    form: 'data form',
    email: 'Email',
    mob: 'Mob',
    kf: 'Customer service',
    gzh: 'Official account'
  },
  header: {
    home: 'Home',
    products: 'Products',
    community: 'Community',
    download: 'Download',
    business: 'Business',
    register: 'Register',
    personalAccount: ' personal account',
    personalInformation: 'personal information',
    corporateAccount: 'corporate account',
    digitalize: '数字化',
    esc: 'ESC'
  },
  common: {
    login: 'Login',
    userName: 'userName',
    password: 'password',
    search:'search',
    sort:'sort',
    confirmation:'confirmation',
    cancel:'cancel',
    save:'save',
    delete:'delete',
    configuration:'configuration',
    softwareDownloading:'Software Downloading',
    otherDownloads:'Other downloads',
    owner:'owner',
    uploading:'uploading',
    pleaseChoose:'please choose',
    pleaseEnter:'please enter',
    reset:'reset',
  },
  login: {
    remember: 'Remember Me',
    Forgot: 'Forgot Password',
    Noaccount: 'No account',
    firstRegistration: 'first Registration'
  },
  spaceType:{
    personalSpace:'Personal space',
    joinCompany:'Companies that have joined',
    backstage:'backstage',
    history:'Item browsing history',
    createGroup:'Create a group',
    editGrouping:'edit grouping',
    componentName:'name',
    enterpriseCoding:'coding',
    cover:'cover plot',
  },
  project:{
    name:'name',
    coding:'coding',
    address:'address',
    cover:'cover plot',
    monomer:'monomer',
    monomerName:'Monomer name',
    monomercoding:'Monomer coding',
    floorone:'Above-ground floor',
    floorTwo:'Underground floor',   
    create:'Create a New Project',
    itemSetting:'item setting',
    editItem:'edit item'
  },
  encoder:{
    encodedLibrary:'Classification coding library',
    standard:'No encoding standard has been selected',
    sharedCoding:'Shared coding',
    enterprise:'enterprise',
    folder:'folder',
  },
  property:{
    specification:'attribute specification list',
    classification:'Attribute classification list',
    attribute:'attribute list',
    sampleName:'sample name',
    copyLocation:'copy location',
    targetEnterprise:'targetEnterprise',
    folder:'folder',
    enterprise:'enterprise',
    property:'property',
    publicSpace:'publicSpace',
    targetItem:'targeTItem',
  },
  codingRule:{
    createRule:'create rule',
    help:'help',
    ruleName:'rule name',
    addFloor:'add floor',
    addCodification:'add codification',
    addSerialNumber:'add serialNumber',
    additiveAttribute:'additive attribute',
    addUserDefined:'add user defined',
    saveSettings:'save settings',
    mappingTable:'mapping Table',
    readingFloor:'reading floor',
    readParameter:'read parameter',
    defineMappings:'Define encodings and mappings',
    writeContent:' write in content',
  },
  
}
